<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Branches Addresses</h3>
          <div class="justify-content-between">
            <router-link :to="{name: 'merchant_create_address'}" class="ps-btn btn-sm">Add Branch Address</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--invoices table-bordered">
            <thead class="thead-light">
            <tr>
              <th>Branch Name</th>
              <th>Street</th>
              <th>City</th>
              <th>Country</th>
              <th>Phone Number</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="address in addresses" :key="address.id">
              <td>{{ address.name }}</td>
              <td>{{ address.street }}</td>
              <td>{{ address.city }}</td>
              <td>{{ address.country }}</td>
              <td>{{ address.phone_number }}</td>
              <td>
                 <router-link :to="{name: 'merchant_edit_address', params: {id: address.id}}"><i class="fa fa-edit" /></router-link>
                 <a class="ml-3" href="#" v-on:click="onDelete(address.id)"><i class="fa fa-trash" /></a>
              </td>
            </tr>
            </tbody>
            <tfoot>
                <tr v-if="(!addresses || addresses.length === 0) && !isLoading">
                  <td colspan="6" class="text-center m-4 text-danger">No addresses available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="6" class="text-center m-4 text-danger">Loading addresses...</td>
                </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import global from '@/mixins/global';
import axios from "axios";
import {mapState} from "vuex";

export default {
  mixins:[global],
  data() {
    return {
      isLoading: true,
      addresses: [],
      meta: null
    };
  },
  mounted() {
    this.getAddresses();
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },
  methods: {
    getAddresses() {
      this.isLoading = true
      axios.get("address").then((response) => {
          this.addresses = response.data;
          this.isLoading = false;
        }).catch((error) => {
          this.isLoading = false;
        });
    },

    onDelete(addressId) {
      if (confirm('Are you sure you want to delete this address')) {
         axios.delete('address/' + addressId).then((response) => {
            console.log(response);
            this.getAddresses();
         });
      }
    }
  }
}
</script>

<style>
</style>